import React from 'react';
import Square from "../../images/square_icon.svg";
import './index.css';

function Divider(){
    return(
        <div className="divider-container">
                <div className="divider-container__line" />
                <img src={Square} alt="" />
                <div className="divider-container__line" />
        </div>
    )
}

export default Divider;
