import React from "react";
import Heading from "../../components/heading";
import "./index.css";
import { motion } from "framer-motion";

function Trash() {
  const pageVariants = {
    initial: {
      opacity: 0,
      y: "-10vh",
      scale: 0.8,
    },
    in: {
      opacity: 1,
      y: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      y: "100vh",
      scale: 1.2,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.8,
  };

  return (
    <motion.div
      className="trash-pickup"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Heading
        img="https://images.unsplash.com/photo-1540980193882-9fc6e90e29c8?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1347&q=80"
        title="Trash Pickup"
      />

      <div className="trash-pickup__information">
        <div className="trash-pickup__information-item">
          <img
            src="https://images.unsplash.com/photo-1612145342813-849b0c3e7f71?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTV8fHRyYXNoJTIwdHJ1Y2t8ZW58MHwwfDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
            alt="Trash pickup service"
          ></img>
          <div className="trash-pickup__information-item-text trash-pickup__information-item-text-y">
            <h3>Coldsprings Township Service By</h3>
            <p>
              <span className="bold">American Waste/GFL Environmental</span>
            </p>
            <p>3947 US-131</p>
            <p>Kalkaska, MI 49646</p>
            <p>(231)-943-8088</p>
          </div>
        </div>

        <div className="trash-pickup__information-item">
          <div className="trash-pickup__information-item-text trash-pickup__information-item-text-x">
            <h3>Private Roads</h3>
            <p>
              Trash must be placed at the intersection of there private roads
              and the nearest county road. American Waste{" "}
              <span className="bold">DOES NOT</span> pick up trash from private
              roads.
            </p>
          </div>
          <img
            src="https://images.unsplash.com/photo-1606500307322-61cf2c98aab3?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTB8fHByaXZhdGUlMjByb2FkfGVufDB8MHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
            alt="private roads"
          ></img>
        </div>

        <div className="trash-pickup__information-item">
          <img
            src="https://images.unsplash.com/photo-1500618506235-88a25423764f?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=699&q=80"
            alt="large item pickup"
          ></img>
          <div className="trash-pickup__information-item-text trash-pickup__information-item-text-y">
            <h3>Large Item Pickup</h3>
            <p>
            Residents are allowed one (1) large item pickup every month. Large items can be placed alongside your weekly trash on Sunday evening for pick-up by American Waste/GFL Environmental.  Stickers are no longer required.  Large item should be picked up along with your trash.  Contact American Waste/GFL Environmental with any questions:  (231) 943-8088.
            </p>
            <p>
              <span className="bold">
                Please notify American Waste Services prior to a large item pickup.
              </span>
            </p>
          </div>
        </div>

        <div className="trash-pickup__information-item">
          <div className="trash-pickup__information-item-text trash-pickup__information-item-text-x">
            <h3>Holidays</h3>
            <p>
              When a holiday falls on Monday trash pickup for that week would be
              on a Tuesday.
            </p>
          </div>
          <img
            src="https://images.unsplash.com/photo-1479740030693-66ad10f3a7b0?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
            alt="holidays"
          ></img>
        </div>

        <div className="trash-pickup__information-item">
          <img
            src="https://images.unsplash.com/photo-1611567332772-a9ddb21695cd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1900&q=80"
            alt="quantity"
          ></img>
          <div className="trash-pickup__information-item-text trash-pickup__information-item-text-y">
            <h3>Quantity</h3>
            <p>
              Each Township residence is allowed five (5), thirty (30) gallon
              bags of trash per week.
            </p>
          </div>
        </div>

        <div className="trash-pickup__information-item">
          <div className="trash-pickup__information-item-text trash-pickup__information-item-text-x">
            <h3>When To Call American Waste/GFL</h3>
            <ul>
              <li>Questions about large item pickup</li>
              <li>Special questions about hazordous materials</li>
              <li>
                Problems with regular pickup
              </li>
              <li>Problems with large item pickup</li>
            </ul>
          </div>
          <img
            src="https://images.unsplash.com/photo-1532726635173-491f83dcce3c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80"
            alt="when to call"
          ></img>
        </div>
      </div>
    </motion.div>
  );
}

export default Trash;
