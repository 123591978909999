import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [u, setU] = useState({ isVerified: false });
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    

    async function verifyUser() {
        const accessToken = await getAccessTokenSilently();
        try {
            
            console.log(accessToken)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/users/verify`, {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                }
            })
            setU({ isVerified: res.data.verfied});
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            verifyUser()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])

    return (
        // Use the UserContext.Provider to pass down the user state
        <UserContext.Provider value={{ u, setU }}>
            {children}
        </UserContext.Provider>
    );
};
