import React, { useState } from "react";
import 'date-fns';
import Divider from "../../components/divider";
import { TextField, Button } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "./index.scss";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { addParkSubmission } from "../../actions/parkActions";

function Parks() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [btnText, setBtnText] = useState('Submit');

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addParkSubmission(formData))
    setFormData({
      fname: "",
      lname: "",
      email: "",
      phone: "",
      guests: "",
      reserveDate: "",
      message: ""
    });
    setBtnText("Submitted!")
  };

  const pageVariants = {
    initial: {
      opacity: 0,
      y: "-10vh",
      scale: 0.8,
    },
    in: {
      opacity: 1,
      y: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      y: "100vh",
      scale: 1.2,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.8,
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <motion.div
      className="parks__container ordinances-container"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="parks__section">
        <h2>Parks and Recreation</h2>
        <p className="parks__section__tagline">
          To reserve the park please enter all the information the fields below,
          You should receive a confirmation email shortly afterwards. If you
          have any further questions please  us.
        </p>
      </div>
      <Divider />

      <div className="parks__section">
        <form className="parks__form" onSubmit={handleSubmit}>
          <div className="parks__input__section">
            <TextField
              className="parks__input__item"
              required
              type="text"
              variant="outlined"
              label="First Name"
              value={formData.fname}
              onChange={(e) => setFormData({...formData, fname: e.target.value})}
            />
            <TextField
              className="parks__input__item"
              required
              type="text"
              placeholder="Last Name"
              variant="outlined"
              label="Last Name"
              value={formData.lname}
              onChange={(e) => setFormData({...formData, lname: e.target.value})}
            />
          </div>
          <div className="parks__input__section">
            <TextField
              className="parks__input__item"
              required
              type="email"
              placeholder="Email"
              variant="outlined"
              label="Email"
              value={formData.email}
              onChange={(e) => setFormData({...formData, email: e.target.value})}
            />
            <TextField
              className="parks__input__item"
              required
              type="phone"
              placeholder="Phone"
              variant="outlined"
              label="Phone"
              value={formData.phone}
              onChange={(e) => setFormData({...formData, phone: e.target.value})}
            />
          </div>
          <div className="parks__input__section">
            <TextField
              className="parks__input__item"
              required
              type="number"
              placeholder="# of Guests"
              variant="outlined"
              label="Guests"
              value={formData.guests}
              onChange={(e) => setFormData({...formData, guests: e.target.value})}
            />
            <input className="custom__input__date" onChange={(e) => setFormData({...formData, reserveDate: e.target.value})} type="date" />
          </div>
          <TextField className="park__messagebox" placeholder="Reason for renting?" label="Reasoning..." required multiline rows={8} id="message" variant="outlined" value={formData.message} onChange={(e) => setFormData({...formData, message: e.target.value})}/>
          <div className="parks__submission__btn__group">
            <Button variant="contained" type="submit">{btnText}</Button>
          </div>
        </form>
      </div>
      <div className="park__faq__container">
        <Divider />
        <h4>FAQ</h4>
        <Accordion className="park__accordion__container" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary className="park__accordion__header__container"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className="park__accordion__header"><span className="park__header__question">Q. </span>General settings</Typography>
          </AccordionSummary>
          <AccordionDetails className="park__accordion__description">
            <span className="park__accordion__description__tag">
              Nulla facilisi. Phasellus sollicitudin nulla et quam mattisfeugiat. Aliquam eget maximus est, id dignissim quam. quam mattisfeugiat. Aliquam eget maximus est, id dignissim quam. quam mattisfeugiat. Aliquam eget maximus est, id dignissim quam. quam mattisfeugiat. Aliquam eget maximus est, id dignissim quam.
            </span>
          </AccordionDetails>
        </Accordion>
      </div>
    </motion.div>
  );
}

export default Parks;
