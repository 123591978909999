import axios from "axios";
import {
    SET_CONTACTS,
    DELETE_CONTACT
} from "./types";

export  const getContactSubmissions = (data) => dispatch => {
    axios.get("/api/v1/contact-submissions")
        .then(res => {
            dispatch(setContactSubmissions(res.data));
        })
        .catch(err => console.log(err))
}

export const deleteContactSubmission = (data) => dispatch => {
    console.log(data.id)
    axios.post("/api/v1/contact-submissions/delete", data)
    .then(res => {
        let i= 3;
        i++;
        console.log(`i is here ${i}`)
        dispatch(getContactSubmissions());
    })
        .catch((error) => {
        console.log(error);
    })
}

export const setContactSubmissions = data => {
    return {
        type: SET_CONTACTS,
        payload: data
    };
}
export const deleteContactSumbission = data => {
    return {
        type: DELETE_CONTACT,
        payload: data
    }
}