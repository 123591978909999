import { motion } from "framer-motion";
import React, { useEffect} from "react";
import Border from "../../components/border";
import Member from "../../components/member";
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import "./index.css";
import "./mobile.css";
import { getBoardMembers } from "../../actions/boardActions";

function Board() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBoardMembers());
  }, [dispatch])
  const boardMembers = useSelector(state => state.board.members)

  const pageVariants = {
    initial: {
      opacity: 0,
      y: "-10vh",
      scale: 0.8,
    },
    in: {
      opacity: 1,
      y: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      y: "100vh",
      scale: 1.2,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.8,
  };

  return (
    <motion.div
      className="board"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Border style={{marginTop: '3rem'}} title="Meet Our Members" />
      <div className="board__members">
        {
          (boardMembers.length > 0) ? ( 
            boardMembers.map((item, i) => {
              return <Member 
                img={`https://coldspringstownship.com/profiles/${item.profilePicture}`}
                name={`${item.fname} ${item.lname}`}
                position={item.title}
                number={item.phone}
                email={item.email}
              />
            })
          ) : <CircularProgress />
        }
      </div>
    </motion.div>
  );
}

export default Board;
