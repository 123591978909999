import { Paper } from "@material-ui/core";
import React from "react";
import "./index.css";

function Member(props) {
  return (
    <Paper elevation={4} className="member">
      <img
        className="member-img"
        src={props.img ? props.img : "./images/icons/placeholder-image.png"}
      />
      <div className="member__content">
        <h3>{props.name}</h3>
        <h4>{props.position}</h4>
        <p>{props.info}</p>
        <p className="member__content-icons">
          <img src="../images/icons/phone.svg" alt="Call us here!" /> {props.number}
        </p>
        <p className="member__content-icons">
          <img src="../images/icons/email.svg" alt="Email us here!" /> {props.email}
        </p>
      </div>
    </Paper>
  );
}

export default Member;
