import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { getMinutes } from "../../actions/minutesActions"
import Link from "@material-ui/icons/Link";
import React, { useState, useRef, useEffect, useMemo, useCallback} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import Divider from "../../components/divider";
import "./index.scss";
import { Tooltip } from "@material-ui/core";

import axios from "axios";

function Minutes() {
  const mins = useSelector(state => state.minutes);
  const dispatch = useDispatch();
  const [link, setLink] = React.useState('');
  const gridRef = useRef();

  const [columnDefs, setColumnDefs] = useState([
    {field: 'title', filter: true, resizable: true},
    {field: 'year', filter: true, resizable: true},
    {field: 'month', filter: true, resizable: true},
  ]);

  const cellClickedListener = useCallback( event => {
    window.open(`https://coldspringstownship.com/minutes/${event.data.filePath}`)
  }, []);

    // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo( ()=> ({
    sortable: true
  }));

  const getMeetingLink = () => {
    axios.get('/api/v1/settings/meeting-link')
      .then((res) => {
        setLink(res.data.meetingLink)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    dispatch(getMinutes())
    getMeetingLink()
  }, [dispatch])

  const pageVariants = {
    initial: {
      opacity: 0,
      y: "-10vh",
      scale: 0.8,
    },
    in: {
      opacity: 1,
      y: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      y: "100vh",
      scale: 1.2,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.8,
  };

  const gridReady = () => {
    gridRef.current.api.sizeColumnsToFit()
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="minutes__header">
        <h1>Meetings/Minutes</h1>
      </div>
      <div className="minutes__secondary__header">
          <p>
          All notes from meetings can be found below. If you think we are missing one please let us know!
          </p>
          <div style={{ display: 'flex'}}>
            <p style={{ color: 'darkgrey', fontWeight: '700' }}>Virtual Meeting Link</p>
            <Tooltip placement="right" title={link}>
              <a href={link} target="_blank" rel="noreferrer noopener">
                <Link className="minutes__meeting__link" />
              </a>
            </Tooltip>
          </div>
          <Divider />
      </div>
      <div className="ag-theme-alpine" style={{width: 1200, height: 800, margin: '1rem auto 3rem auto'}}>
        <p style={{ color: 'darkgrey', textAlign: 'center' }}>**Click the row to download the corresponding minutes file.**</p>
       <AgGridReact
           ref={gridRef} // Ref for accessing Grid's API
           rowData={mins.minutes} // Row Data for Rows
           columnDefs={columnDefs} // Column Defs for Columns
           defaultColDef={defaultColDef} // Default Column Properties
           onCellClicked={cellClickedListener} // Optional - registering for Grid Event
           onCell
           onGridReady={gridReady}
           pagination
           />
     </div>
    </motion.div>
  );
}

export default Minutes;
