/* eslint-disable import/no-anonymous-default-export */
import {
    ADD_MINUTE,
    GET_MINUTES,
    DELETE_MINUTE
} from "../actions/types";

const initialState = {
    loaded: false,
    minutes: []
}

export default function(state = initialState, action) {
    switch (action.type) {
        case ADD_MINUTE:
            return {
                ...state,
                minutes: [...state.minutes, action.payload]
            }
        case GET_MINUTES:
            return {
                ...state,
                minutes: action.payload
            }
        case DELETE_MINUTE:
            return {
                ...state,
                minutes: state.minutes.filter((minute) => minute._id !== action.payload)
            }
        default: {
            return state;
        }
    }
}