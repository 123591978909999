import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './index.css';

function Footer() {
    const [ isFooter, setFooter ] = useState(true);
    let location = useLocation();

    const pathurl = () => {
        if(location.pathname.includes('/dashboard')) {
            setFooter(false);
        } else {
            setFooter(true)
        }
    }

    useEffect(() => {
        pathurl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location])
    
    return (
        <div className={`footer__container ${isFooter ? "" : "no__footer"}`}>
            <div className="footer__section">
                <div className="footer__container__item">
                    <img src ="../../images/logo.png" alt="" />
                    <p>Proudly serving our community since 1983. Making sure our township citizens are able to come to us with all needs regarding the government within our township.</p>
                </div>
                <div className="footer__container__item">
                    <h3>Quick Links</h3>
                    <Link to="/" className="
                    footer__link__item">Home</Link>
                    <Link to="/government/meetings-minutes" className="
                    footer__link__item">Meetings & Minutes</Link>
                    <Link to="/government/board-members" className="
                    footer__link__item">Board Members</Link>
                    <Link to="/government/ordinances" className="
                    footer__link__item">Ordinances</Link>
                    <Link to="/government/tax" className="
                    footer__link__item">Tax</Link>
                </div>
                <div style={{paddingTop: '3.8rem'}} className="footer__container__item">
                    <Link to="/parks-recreation" className="
                    footer__link__item">Parks & Recreation</Link>
                    <Link to="/trash" className="
                    footer__link__item">Services</Link>
                    <Link to="/trash" className="
                    footer__link__item">Trash</Link>
                    <Link to="/cemetery" className="footer__link__item">Cemetery</Link>
                    <Link to="/contact" className="
                    footer__link__item">Contact Us</Link>
                </div>
                <div className="footer__container__item">
                    <h3>Contact Us</h3>
                    <div className="footer__item__container">
                        <img src="../../images/icons/phone.svg" alt="phone" />
                        <p>(231)-587-8633</p>
                    </div>
                    <div className="footer__item__container">
                        <img src="../../images/icons/email.svg" alt="email icon" />
                        <p>clerk@coldspringstwp.com</p>
                    </div>
                    <div className="footer__item__container">
                        <img src="../../images/icons/pin.svg" alt="pin icon" />
                        <p>Coldsprings Township Hall 6515 County Road 571 NE Mancelona, MI 49659</p>
                    </div>
                </div>
            </div>
            <div className="footer__copyright">
                <p>©2021 Coldsprings Township</p>
            </div>
        </div>
    )
}
export default Footer;