import React, { useEffect } from 'react';
import { getBoardMembers, reOrderBoardMembers } from '../../../../../actions/boardActions';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Member from './Member';

function ViewMembers() {
    const dispatch = useDispatch();
    const boardMembers = useSelector(state => state.board.members);

    useEffect(() => {
        dispatch(getBoardMembers());
    }, [dispatch])

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
      };

    const onDragEnd = (res) => {
        if (!res.destination) {
            return;
        }
      
        if (res.destination.index === res.source.index) {
        return;
        }
        
        const newMembers = reorder(
            boardMembers,
            res.source.index,
            res.destination.index
        );
        console.log(newMembers)
        dispatch(reOrderBoardMembers(newMembers));
    }

    return (
        <DragDropContext
            onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
                {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {
                            boardMembers.map((item, i) => {
                                return <Member key={item._id} id={item._id} index={i} fname={item.fname} lname={item.lname} title={item.title} email={item.email} phone={item.phone} />
                            })
                        }
                        {provided.placeholder}
                    </div>
                    )
                }
                
            </Droppable>
        </DragDropContext>
    )
}

export default ViewMembers;
