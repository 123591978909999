import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Logo from "../../../images/logo.png";
import Hamburger from "../../../images/menu.svg";
import X from "../../../images/x.svg";
import { connect } from "react-redux";
import "./index.css";
import LoginDisplay from "../../loginDisplay";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AuthenticationButton from "../../button/Authenticate";

function MainNavigation(props) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState();
  const [anchorQ, setAnchorQ] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openMobile = (e) => {
    document.getElementsByClassName("mobile-nav")[0].style = "display:flex;";
    document.body.style.overflow = "hidden";
  };

  const closeMobile = (e) => {
    let govDiv = document.getElementsByClassName("mobile-nav__government")[0];
    let servicesDiv = document.getElementsByClassName(
      "mobile-nav__services"
    )[0];
    let mobileNav = document.getElementsByClassName("mobile-nav")[0];
    let mobileItems = document.getElementsByClassName("mobile-nav-item");

    mobileNav.style = "display:none;";

    if (
      !govDiv.classList.contains("hidden") ||
      !servicesDiv.classList.contains("hidden")
    ) {
      govDiv.classList.add("hidden");
      servicesDiv.classList.add("hidden");
      [...mobileItems].forEach((el) => {
        el.classList.remove("hidden");
      });
    }

    document.body.style.overflow = "visible";
  };

  const switchPage = (e, page) => {
    e.preventDefault();
    switch (page) {
      case "home":
        history.push("/");
        closeMobile();
        break;

      case "parks":
        history.push("/parks-recreation");
        closeMobile();
        break;

      case "services":
        document
          .getElementsByClassName("mobile-nav__services")[0]
          .classList.remove("hidden");
        let els = document.getElementsByClassName("mobile-nav-item");

        if (els[0].classList.contains("hidden")) {
          [...els].forEach((el) => {
            el.classList.remove("hidden");
            document
              .getElementsByClassName("mobile-nav__services")[0]
              .classList.add("hidden");
          });
        } else {
          [...els].forEach((el) => {
            el.classList.add("hidden");
          });
        }
        break;

      case "government":
        document
          .getElementsByClassName("mobile-nav__government")[0]
          .classList.remove("hidden");
        let els2 = document.getElementsByClassName("mobile-nav-item");

        if (els2[0].classList.contains("hidden")) {
          [...els2].forEach((el) => {
            el.classList.remove("hidden");
            document
              .getElementsByClassName("mobile-nav__government")[0]
              .classList.add("hidden");
          });
        } else {
          [...els2].forEach((el) => {
            el.classList.add("hidden");
          });
        }

        break;

      case "contact":
        history.push("/contact");
        closeMobile();
        break;

      case "meetings":
        history.push("/government/meetings-minutes");
        closeMobile();
        break;

      case "board":
        history.push("/government/board-members");
        closeMobile();
        break;

      case "ordinances":
        history.push("/government/ordinances");
        closeMobile();
        break;

      case "tax":
        history.push("/government/tax");
        closeMobile();
        break;

      case "trash":
        history.push("/trash");
        closeMobile();
        break;

      case "cemetery":
        history.push("/cemetery");
        closeMobile();
        break;

      default:
        break;
    }
  };

  return (
    <nav className="nav__container">
      <div className="mobile-nav" style={{ display: "none" }}>
        <img onClick={closeMobile} src={X} alt="modal close" />
        <button
          className="mobile-nav-item"
          id="mobile-nav-home"
          onClick={(e) => switchPage(e, "home")}
        >
          Home
        </button>
        <button
          className="mobile-nav-item"
          id="mobile-nav-government"
          onClick={(e) => switchPage(e, "government")}
        >
          Government
        </button>
        <div className="mobile-nav__government hidden">
          <button onClick={(e) => switchPage(e, "government")}>
            Government
          </button>
          <button onClick={(e) => switchPage(e, "meetings")}>
            Meetings & Minutes
          </button>
          <button onClick={(e) => switchPage(e, "board")}>Board Members</button>
          <button onClick={(e) => switchPage(e, "ordinances")}>
            Ordinances
          </button>
          <button onClick={(e) => switchPage(e, "tax")}>Tax Information</button>
        </div>
        <button
          className="mobile-nav-item"
          id="mobile-nav-parks"
          onClick={(e) => switchPage(e, "parks")}
        >
          Parks & Recreation
        </button>
        <button
          className="mobile-nav-item"
          id="mobile-nav-services"
          onClick={(e) => switchPage(e, "services")}
        >
          Services
        </button>
        <div className="mobile-nav__services hidden">
          <button onClick={(e) => switchPage(e, "services")}>Services</button>
          <button onClick={(e) => switchPage(e, "trash")}>Trash Pickup</button>
          <button onClick={(e) => switchPage(e, "cemetery")}>Cemetery</button>
        </div>
        <button
          className="mobile-nav-item"
          id="mobile-nav-contact"
          onClick={(e) => switchPage(e, "contact")}
        >
          Contact
        </button>
      </div>
      <div className="nav__top__container">
        <p className="hours__on">
          Open Hours of Township Government Monday & Thursday 1:00PM - 4:00PM
        </p>
        {props.auth.isAuthenticated ? (
          <LoginDisplay />
        ) : (
          <AuthenticationButton />
        )}
      </div>
      <div className="nav__bottom__container">
        <div className="nav__logo__container">
          <NavLink style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', color: 'black'}} to="/">
            <img src={Logo} alt="Township hall logo" />
            <h1>Coldsprings Township</h1>
          </NavLink>
        </div>
        <div className="nav__items">
          <NavLink activeClassName="is-active"  className="nav__item" exact to="/">
            Home
          </NavLink>
          <div className="govNav">
            <p style={{ cursor: 'pointer'}} onClick={(e) => handleClick(e)} className="nav__item">Government</p>
            <Menu
              anchorEl={anchorEl}
              open={anchorEl}
              onClose={() => setAnchorEl(null)}
              disableScrollLock={true}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              <Link style={{textDecoration: 'none', color: 'black'}} to="/government/meetings-minutes"><MenuItem onClick={handleClose}>Meetings & Minutes</MenuItem></Link>
              <Link style={{textDecoration: 'none', color: 'black'}} to="/government/board-members"><MenuItem onClick={handleClose}>Board Members</MenuItem></Link>
              <Link style={{textDecoration: 'none', color: 'black'}} to="/government/ordinances"><MenuItem onClick={handleClose}>Ordinances</MenuItem></Link>
              <Link style={{textDecoration: 'none', color: 'black'}} to="/government/tax"><MenuItem onClick={handleClose}>Tax Info</MenuItem></Link>
              <Link style={{textDecoration: 'none', color: 'black'}} to="/government/voter-info"><MenuItem onClick={handleClose}>Voter Info</MenuItem></Link>
            </Menu>
          </div>
          <NavLink activeClassName="is-active" className="nav__item" to="/parks-recreation">
            Parks & Recreation
          </NavLink>
          <div className="serviceNav">
            {/* <Link className="nav__item">Services</Link> */}
            <p style={{ cursor: 'pointer'}} onClick={(e) => setAnchorQ(e.currentTarget)} className="nav__item">Services</p>
            <Menu
              anchorEl={anchorQ}
              open={anchorQ}
              onClose={() => setAnchorQ(null)}
              disableScrollLock={true}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              <Link style={{textDecoration: 'none', color: 'black'}} to="/trash"><MenuItem onClick={() => setAnchorQ(null)}>Trash</MenuItem></Link>
              <Link style={{textDecoration: 'none', color: 'black'}} to="/cemetery"><MenuItem onClick={() => setAnchorQ(null)}>Cemetery</MenuItem></Link>
            </Menu>
          </div>
          <NavLink activeClassName="is-active" className="nav__item" to="/contact">
            Contact
          </NavLink>
        </div>
        <img
          onClick={(e) => openMobile(e)}
          src={Hamburger}
          alt="hamburger mobile menu"
          className="nav__container-hamburger"
          style={{ display: "none" }}
        />
      </div>
    </nav>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(MainNavigation);
