import React, { useState, useEffect } from 'react';
import SideNav from '../../../../components/navigation/dashsidenav';
import DashboardNav from '../../../../components/navigation/dashboardnav';
import Paper from '@material-ui/core/Paper';
import { Typography, TextField, Button, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { addMinute, deleteMinute, getMinutes } from '../../../../actions/minutesActions';
import { MinuteCont } from '../../../../components/minute';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import axios from 'axios'
import './index.scss';

function Minutes() {
    const minutes = useSelector(state => state.minutes);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [minId, setMinId] = useState();
    const [agree, setAgree] = useState(false);
    const [file, setFile] = useState();
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [title, setTitle] = useState("");
    const [link, setLink] = useState();
    const [btnText, setBtnText] = useState("Update");

    const months = [
        {
            id: 0,
            month: "January"
        },
        {
            id: 1,
            month: "February"
        },
        {
            id: 2,
            month: "March"
        },
        {
            id: 3,
            month: "April"
        },
        {
            id: 4,
            month: "May"
        },
        {
            id: 5,
            month: "June"
        },
        {
            id: 6,
            month: "July"
        },
        {
            id: 7,
            month: "August"
        },
        {
            id: 8,
            month: "September"
        },
        {
            id: 9,
            month: "October"
        },
        {
            id: 10,
            month: "November"
        },
        {
            id: 11,
            month: "December"
    }];
    const years = [2015, 2016, 2017,2018,2019,2020,2021,2022,2023,2024,2025,2026,2027,2028,2029,2030]
    
    useEffect(() => {
        dispatch(getMinutes());
        getMeetingLink()
        if(agree === true) {
            deleteMin();
        }
    }, [dispatch, agree])
    const deleteMin = () => {
        if(agree === true) {
            console.log(minId)
            setOpen(false);
            dispatch(deleteMinute({id: minId}));
            dispatch(getMinutes());
            setAgree(false);
        }
        dispatch(getMinutes());
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        const fileData = new FormData();
        fileData.append('file', file);
        fileData.append('title', title);
        fileData.append('year', year);
        fileData.append('month', month);
        dispatch(addMinute(fileData));
        return clearForm();
    }
    const clearForm = () => {
        setTitle('');
        setMonth('');
        setYear('');
        setFile({});
    }
    
    const trashClick = (id) => {
        setOpen(true)
        setMinId(id);
    }

    const getMeetingLink = () => {
        axios.get('/api/v1/settings/meeting-link')
            .then((res) => {
                setLink(res.data.meetingLink)
            })
            .catch(err => console.log(err))
    }

    const updateLink = () => {
        setBtnText("Submitted")
        const data = {link }
        axios.post('/api/v1/settings/update-meeting-link', data)
            .then((res) => {
                
                setLink(res.data.meetingLink)
            })
            .catch(err => console.log(err))
    }

    return (
        <div className="dashboard__parent__container">
            <SideNav />
            <div className="dashboard__child__container">
                <DashboardNav />
                <Paper className="dashboard__ordinance__header" elevation={4}>
                    <Typography style={{color: '#49CA97'}} variant="h5">Meetings & Minutes Management</Typography>
                    <Typography variant="p">Manage all meetings files here!</Typography>
                </Paper>
                <Paper style={{ margin: '1rem 2rem', padding: '1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} elevation={4}>
                    <Typography style={{color: '#49CA97'}} variant="body1">Virtual Meeting Link</Typography>
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                        <TextField variant='outlined' value={link} onChange={(e) => setLink(e.target.value)} />
                        <Button style={{ marginLeft: '1rem'}} onClick={() => updateLink()}>{btnText}</Button>
                    </div>
                </Paper>
                <Paper elevation={4} className="dashboard__minutes__container">
                    <div className="dashboard__minutes__section">
                        <div className="dashboard__minutes__section__item">
                            <Typography variant="h5">Add Minutes</Typography>
                            <form className="pdf__form" onSubmit={(e) => onSubmit(e)}>
                                <TextField className="minutes__input__item" onChange={(e) => setTitle(e.target.value)} required id="Title" value={title} label="Title" variant="outlined" />
                                <TextField
                                    className="minutes__input__item"
                                    id="select-year"
                                    select
                                    label="Year"
                                    value={year}
                                    onChange={(e) => setYear(e.target.value)}
                                    variant="outlined"
                                    required
                                    >
                                    {years.map((option) => (
                                        <MenuItem key={option} value={option}>
                                        {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    className="minutes__input__item"
                                    id="select-month"
                                    select
                                    label="Month"
                                    value={month}
                                    onChange={(e) => setMonth(e.target.value)}
                                    variant="outlined"
                                    required
                                    >
                                    {months.map((option) => (
                                        <MenuItem key={option.id} value={option.month}>
                                        {option.month}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <input required accept="application/pdf" className="file__upload__btn minutes__input__item" onChange={(e)=> setFile(e.target.files[0])} type="file" />
                                <Button className="minutes__submission__btn" type="submit" variant="contained">Add Minutes</Button>
                            </form>
                        </div>
                        <div className="dashboard__minutes__section__item">
                            <Typography variant="h5">Manage Minutes/Meetings</Typography>
                            <div className="minute__dashboard__help__display">
                                <span>Minute Title</span>
                                <span>Delete</span>
                            </div>
                            <div className="minute__dashboard__delete__divider" />
                            {
                                !minutes.loaded ? (
                                minutes.minutes.map((item, i) => {
                                    return <MinuteCont trashClick={trashClick} key={i} id={item._id} title={item.title} />
                                })) : []
                            }
                        </div>
                    </div>
                </Paper>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                className="dashboard__minutes__modal"
                onClose={() => setOpen(!open)}
                closeAfterTransition>
                <Fade in={open}>
                    <div className="dashboard__minutes__modal__content">
                        <h4 id="transition-modal-title">Confirmation</h4>
                        <p id="transition-modal-description">Are you sure you want to delete this minute/meeting?</p>
                        <div className="dashboard__minutes__modal__btn__group">
                            <Button onClick={() => setOpen(false)} variant="contained">Cancel</Button>
                            <Button onClick={()=> {
                                setAgree(true);
                                setOpen(false);
                            }} variant="contained" style={{backgroundColor: '#ff4f4d'}}>Confirm Delete</Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}

export default Minutes;
