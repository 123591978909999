import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';

function QuickLink(props){

    return(
        <Link to={props.path} className="quick-link">
            <img src={props.img} alt={props.name}/>
            <p>{props.name}</p>
        </Link>
    )
}

export default QuickLink;