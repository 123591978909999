import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain="dev-4fea6m1bhyef3zai.us.auth0.com"
      clientId="kBROIuyj2R6Vxmzaq6zWcZcWKMwf9lUs"
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      redirectUri="https://coldspringstownship.com"
      audience="https://coldspringstownship-api.com"
      scope="read:all"
    >
        {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;