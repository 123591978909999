/* eslint-disable import/no-anonymous-default-export */
import {
    ADD_PARK_SUBMISSION,
    SET_PARK_LOADING,
    GET_PARK_SUBMISSIONS,
    APPROVE_PARK_SUBMISSION,
    REJECT_PARK_SUBMISSION,
    DELETE_PARK_SUBMISSION
} from "../actions/types";

const initialState = {
    loaded: false,
    parkSubmissions: []
}

export default function(state = initialState, action) {
    switch (action.type) {
        case ADD_PARK_SUBMISSION:
            return state;
        case SET_PARK_LOADING:
            return {
                ...state,
                loaded: action.payload
            }
        case GET_PARK_SUBMISSIONS:
            return {
                ...state,
                parkSubmissions: action.payload
            }
        case APPROVE_PARK_SUBMISSION: {
            return {
                ...state,
                parkSubmissions: state.parkSubmissions.map(item => {
                    if(item._id === action.payload._id) {
                        return action.payload;
                    }
                    return item;
                })
            }
        }
        case REJECT_PARK_SUBMISSION: {
            return {
                ...state,
                parkSubmissions: state.parkSubmissions.map(item => {
                    if(item._id === action.payload._id) {
                        return action.payload;
                    }
                    return item;
                })
            }
        }
        case DELETE_PARK_SUBMISSION: {
            return {
                ...state,
                parkSubmissions: state.parkSubmissions.filter((item) => item._id !== action.payload._id)
            }
        }
        default: {
            return state;
        }
    }
}