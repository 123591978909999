/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import ContactSubmissionItem from "../../../../components/contactSubmissionItem";
import DashboardNav from "../../../../components/navigation/dashboardnav";
import SideNav from "../../../../components/navigation/dashsidenav";
import "./index.scss";
import { useDispatch, useSelector } from 'react-redux';
import { getContactSubmissions } from '../../../../actions/contactActions';


function Contact() {
    const dispatch = useDispatch();
    const contactSubmissions = useSelector(state => state.contactSubmissions)

    useEffect(() => {
        dispatch(getContactSubmissions());
        console.log(contactSubmissions)
    }, [dispatch])
        
        return (
            <div className="dashboard__parent__container">
                <SideNav />
                <div className="dashboard__child__container">
                    <DashboardNav />
                    <div style={{ overflowY: 'auto', maxHeight: '100%'}}>
                        <Paper elevation={2} className="dashboard__contact__container">
                            <Typography style={{color: '#49CA97'}} variant="h5">Contact Submissions</Typography>
                            <Typography variant="p">Manage all contact submissions here!</Typography>
                        </Paper>
                        <Paper elevation={2} className="dashboard__contact__container">
                            <div className="dashboard__contact__container__content">
                                {
                                    contactSubmissions.contacts === 0 ? <Typography variant='body1'>No submissions</Typography> :
                                contactSubmissions.contacts.map((item, i) => {
                                    return <ContactSubmissionItem
                                                id={item._id}
                                                name={item.name} 
                                                email={item.email} 
                                                entity={item.entity} 
                                                phone={item.phone} 
                                                message={item.message} 
                                                read={item.read}
                                                date={item.createdAt}
                                                key={item._id} />
                                 })
                                }
                            </div>
                        </Paper>
                    </div>
                </div> 
            </div>
        )
}
export default Contact;
