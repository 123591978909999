import React, { useState } from 'react';
import Divider from "../../components/divider";
import { motion } from 'framer-motion';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import './index.css';

function Contact() {
    const [formData, setFormData] = useState({});
    const [formBtn, setFormBtn] = useState("Submit")
    const entities = ['Taxes', 'Clerk', 'Supervisor']

    const handleSubmit = (e) => {
        console.log(formData)
        e.preventDefault();
        axios.post("/api/v1/contact-submissions/create", formData)
            .then(res => {
                if(res.status === 200) {
                    setFormData({
                        name: '',
                        email: '',
                        entity: '',
                        phone: '',
                        message: ''
                    })
                    setFormBtn("Submitted!")
                }
        })
        .catch(err => console.log(err))
    }
    const pageVariants = {
        initial: {
            opacity: 0,
            y: "-10vh",
            scale: 0.8
        },
        in: {
            opacity: 1,
            y: 0,
            scale: 1
        },
        out: {
            opacity: 0,
            y: "100vh",
            scale: 1.2
        }
    };
    const pageTransition = {
        type: "tween",
        ease: "anticipate",
        duration: .8
    };

    return (
        <motion.div className="__container"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
        >
            <div className="__section">
                <h2>Contact Us</h2>
                <p className="__section__tagline">The only bad question is the one never asked. We are happy to assist you with any and all questions.</p>
            </div>
            <Divider/>
            <div className="__section __info">
                <div className="__info">
                    <img className="__icon" src="./images/icons/pin.svg" alt="location pin" /><p>Coldsprings Township Hall, 6515 County Road NE Mancelona, MI 49659</p>
                </div>
                <div className="__info__section__secondary">
                    <div className="__info">
                        <img className="__icon" src="./images/icons/phone.svg" alt="phone icon" />
                        <p>(231)-587-8633</p>
                    </div>
                    <div className="__info">
                        <img className="__icon" src="./images/icons/fax.svg" alt="fax icon" />
                        <p>(231)-587-8656</p>
                    </div>
                </div>
            </div>
            <div className="__section">
                <form className="__form" onSubmit={handleSubmit}>
                    <div className="__input__section">
                        <TextField className="__input__item" required name="name" label="Name" variant="outlined" value={formData.name} onChange={(e) => setFormData({...formData, name: e.target.value})}/>
                        <TextField className="__input__item" required type="email" label="Email" variant="outlined" value={formData.email} onChange={(e) => setFormData({...formData, email: e.target.value})} />
                    </div>
                    <div className="__input__section">
                        <TextField
                            className="__input__item"
                            select
                            label="Select"
                            value={formData.entity}
                            required
                            onChange={(e) => setFormData({...formData, entity: e.target.value})}
                            variant="outlined"
                            >
                            {entities.map((option) => (
                                <MenuItem key={option} value={option}>
                                {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField className="__input__item" type="phone" label="Phone" variant="outlined" value={formData.phone} onChange={(e) => setFormData({...formData, phone: e.target.value})} />
                    </div>
                    
                    <TextField className="messagebox" label="Message" required multiline rows={8} id="message" variant="outlined" value={formData.message} onChange={(e) => setFormData({...formData, message: e.target.value})}/>
                    <div className="__submit__button">
                        <Button variant="contained" type="submit">{formBtn}</Button>
                    </div>
                    
                </form>
                <div className="__section-persons">
                    <div className="__person">
                        <h4>Raymond Hoffman (Supervisor)</h4>
                        <p><img src="./images/icons/phone.svg" alt="phone icon"/> (231)-587-8633 x116</p>
                        <p><img src="./images/icons/email.svg" alt="email icon"/> rmhoffman44@gmail.com</p>
                    </div>
                    <div className="__person">
                        <h4>Gayenell Gentelia (Clerk)</h4>
                        <p><img src="./images/icons/phone.svg" alt="phone icon"/> (231)-587-8633 x112</p>
                        <p><img src="./images/icons/email.svg" alt="email icon"/> clerk@coldspringstwp.com</p>
                    </div>
                    <div className="__person">
                        <h4>Brenda Smith (Treasurer)</h4>
                        <p><img src="./images/icons/phone.svg" alt="phone icon"/> (231)-587-8633 x114</p>
                        <p><img src="./images/icons/email.svg" alt="email icon"/> treasurer@coldspringstwp.com</p>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}
export default Contact;