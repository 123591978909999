import React, { Component } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import IconButton from '@material-ui/core/IconButton';
import {
    Menu,
    MenuItem
} from '@szhsin/react-menu';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import './index.css';
import { connect } from 'react-redux';
import { logoutUser } from '../../../actions/authActions';

class DashboardNav extends Component {

    onLogoutClick = () => {
        this.props.logoutUser();
    }

    render() {
        return (
            <div className="top__nav__container">
                <div className="top__nav__icons">
                    <IconButton>
                        <Menu className="nav__item" menuButton={<PersonIcon className="top__nav__icon"/>}>
                            <Divider />
                            <MenuItem className="dashnav__menuitem" onClick={this.onLogoutClick}><Link className="menu__item__clear" to="/">Logout</Link></MenuItem>
                        </Menu>
                    </IconButton>
                    
                </div>
            </div>
        )
    }
    
}
const mapStateToProps = state => ({
    auth: state.auth
})
export default connect(
    mapStateToProps,
    {logoutUser}
)(DashboardNav);
