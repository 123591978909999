import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import NatureIcon from '@material-ui/icons/Nature';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { Link, NavLink } from 'react-router-dom';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import ArticleIcon from '@material-ui/icons/PagesOutlined';
import GavelIcon from '@mui/icons-material/Gavel';
import './index.css'

function SideNav() {
    return (
        <div  className="sidenav__container">
            <Link style={{textDecoration: 'none'}} to="/">
            <div className="sidenav__logo__header">
                <img src="../../../images/logo.png" alt="Coldsprings Township" />
                <h2>Coldsprings Twp.</h2>
            </div>
            </Link>
            <div className="sidenav__divider" />
            <div className="sidenav__link__container">
                <NavLink activeClassName="dash__selected" exact to="/dashboard" className="sidenav__link__item">
                    <DashboardIcon className="sidenav__icon" />
                    <p>Dashboard</p>
                </NavLink>
                <NavLink activeClassName="dash__selected" to="/dashboard/park-submissions" className="sidenav__link__item">
                    <NatureIcon className="sidenav__icon" />
                    <p>Parks</p>
                </NavLink>
                <NavLink activeClassName="dash__selected" to="/dashboard/contact-submissions" className="sidenav__link__item">
                    <RecentActorsIcon className="sidenav__icon" />
                    <p>Contact</p>
                </NavLink>
                {/* <NavLink activeClassName="dash__selected" to="/dashboard/news" className="sidenav__link__item">
                    <AmpStoriesIcon className="sidenav__icon" />
                    <p>News</p>
                </NavLink> */}
                <NavLink activeClassName="dash__selected" to="/dashboard/meetings-minutes" className="sidenav__link__item">
                    <MeetingRoomIcon className="sidenav__icon" />
                    <p>Minutes</p>
                </NavLink>
                <NavLink activeClassName="dash__selected" to="/dashboard/ordinances" className="sidenav__link__item">
                    <GavelIcon className="sidenav__icon" />
                    <p>Ordinances</p>
                </NavLink>
                <NavLink activeClassName="dash__selected" to="/dashboard/board-members" className="sidenav__link__item">
                    <GavelIcon className="sidenav__icon" />
                    <p>Board Members</p>
                </NavLink>
                <NavLink activeClassName="dash__selected" to="/dashboard/voter-info" className="sidenav__link__item">
                    <ArticleIcon className="sidenav__icon" />
                    <p>Voter Page</p>
                </NavLink>
            </div>
        </div>
    )
}

export default SideNav;
