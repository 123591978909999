import React from 'react'
import Pdf from "../../components/pdf";
import Divider    from "../../components/divider";
import { motion } from 'framer-motion';
import './index.css';

function Cemetery() {

    const pageVariants = {
        initial: {
          opacity: 0,
          y: "-10vh",
          scale: 0.8
        },
        in: {
          opacity: 1,
          y: 0,
          scale: 1
        },
        out: {
          opacity: 0,
          y: "100vh",
          scale: 1.2
        }
      };
      
      const pageTransition = {
        type: "tween",
        ease: "anticipate",
        duration: .8
      };

    return (
        <motion.div className="cemetery-container"
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
          >
            <h1>Cemetery</h1>
            <p>
                Here you will find all rules, regulations, and maps for our township
                cemetery. If these do not answer your questions please
                reach out to us!
            </p>
            
            <Divider/>

            <div className="cemetery-container__items">
                <Pdf link="google.com" title="Marijuana"/>
                <Pdf link="" title="Firework"/>
                <Pdf link="" title="Private Road"/>
            </div>
        </motion.div>
    )
}

export default Cemetery;
