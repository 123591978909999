import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../../../actions/authActions";
import DashboardNav from "../../../../components/navigation/dashboardnav";
import SideNav from "../../../../components/navigation/dashsidenav";

class Settings extends Component {
    onLogoutClick = (e) => {
        e.preventDefault();
        this.props.logoutUser();
    };
    render() {
        
        return (
            <div className="dashboard__parent__container">
                <SideNav />
                <div className="dashboard__child__container">
                    <DashboardNav />
                    <div>
                        <h1>Settings Page</h1>
                    </div>
                </div> 
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth
});
export default connect(
    mapStateToProps,
    { logoutUser }
)(Settings);