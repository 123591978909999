import DashboardNav from "../../../../components/navigation/dashboardnav";
import SideNav from "../../../../components/navigation/dashsidenav";
import React, { useState, useCallback, useEffect } from 'react'
import { Button, Divider, Paper } from "@material-ui/core";
import { createReactEditorJS } from "react-editor-js"
import { EDITOR_JS_TOOLS } from "./Constants";
import axios from 'axios';

const ReactEditorJS = createReactEditorJS();

function VoterInfo() {
    const editorCore = React.useRef(null);
    const [ isLoaded, setLoaded ] = useState(false);
    const [ page, setPage ] = useState();

    const handleInitialize = useCallback((instance) => {
        editorCore.current = instance
    }, [])

    useEffect(() => {
        axios.get("/api/v1/pages", { params: { name: 'voterinfo' } })
        .then(res => {
            setPage(res.data)
            setLoaded(true)
        })
    }, [])
    

    const handleSave = () => {
        editorCore.current.save()
        .then((res => {
            console.log(res)
            const submit = {
                name: 'voterinfo',
                data: res
            }
            axios.post('/api/v1/pages/update', submit)
                .then((response => {
                    setPage(response.data)
                    console.log(page)
                }))
        }))
        .catch(err => console.log(err))
    }
        
    return (
        <div className="dashboard__parent__container">
            <SideNav />
            <div className="dashboard__child__container">
                <DashboardNav />
                <div style={{ overflowY: 'auto', maxHeight: '100%'}}>
                <Paper sx={{padding: '1rem', display: 'flex', flexDirection: 'column', width: '100%'}} elevation={3}>
                    <div style={{display: 'flex', justifyContent: 'center', padding: '1rem', margin: '2rem'}}>
                        <Button onClick={handleSave} variant="contained" color="secondary">Save</Button>
                    </div>
                    <Divider sx={{ padding: '1rem'}} />
                    <div>
                        {
                            isLoaded ? <ReactEditorJS
                            enableReInitialize={true} 
                            onInitialize={handleInitialize}
                            tools={EDITOR_JS_TOOLS}
                            data={page.data[0]}
                        /> :
                        <p>Loading</p>
                        }
                        
                    </div>
                    <Divider sx={{ padding: '1rem' }} />
                </Paper>
                </div>
            </div> 
        </div>
    )
}
export default VoterInfo;