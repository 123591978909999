import React from 'react'
import './index.css'
import {Link} from 'react-router-dom';

function Previews(props){
    return(
        <div className="preview">
            {props.index===1 ? <div className="preview__img">
                <img src="./images/icons/01.png" alt="01"/>
                <h4>{props.title}</h4>
            </div> :null}
            {props.index===2 ? <div className="preview__img">
                <img src="./images/icons/02.png" alt="02"/>
                <h4>{props.title}</h4>
            </div> :null}
            {props.index===3 ? <div className="preview__img">
                <img src="./images/icons/03.png" alt="03"/>
                <h4>{props.title}</h4>
            </div> :null}
            <p>{props.info}</p>
            <Link to={props.link}>Read More...</Link>
        </div>
    )
}

export default Previews;