import React from 'react';
import './index.css';

function Heading(props){
    return(
        <div className="heading">
            <div className="heading__overlay"><h1>{props.title}</h1></div>
            <img src={props.img} alt="" />
        </div>
    )
}

export default Heading;