import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import ordinanceReducer from "./ordinanceReducer";
import contactReducer from "./contactReducer";
import minuteReducer from "./minuteReducer";
import parkReducer from "./parkReducer";
import boardReducer from "./boardReducer";
export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  ordinances: ordinanceReducer,
  minutes: minuteReducer,
  contactSubmissions: contactReducer,
  parks: parkReducer,
  board: boardReducer
});