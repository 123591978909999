import React from "react";
import "./index.css";
import Heading from "../../components/heading";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { motion } from "framer-motion";
import InfoIcon from '@mui/icons-material/Info';

function TaxInfo() {
  const pageVariants = {
    initial: {
      opacity: 0,
      y: "-10vh",
      scale: 0.8,
    },
    in: {
      opacity: 1,
      y: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      y: "100vh",
      scale: 1.2,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.8,
  };

  return (
    <motion.div
      className="tax"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Heading
        title="Tax Information"
        img="https://images.unsplash.com/photo-1579444741963-5ae219cfe27c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
      />
      <div style={{ display: 'flex', justifyContent: 'center', margin: '2rem', gap: '2rem'}}>
          <a href="https://coldspringstownship.com/files/PAID-UNPAID_THROUGH_09-14-2023.pdf" download style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', textDecoration: 'none', color: 'black', backgroundColor: '#f5f5f5', padding: '1rem' }}>
            <FileDownloadIcon />
            <p>Winter 2023 Taxes Paid-Unpaid</p>
          </a>
          {/* <a href="/files/SUMMER_2022_TAXES_PAID-UNPAID_THROUGH_12-31-2022.pdf" download style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', textDecoration: 'none', color: 'black', backgroundColor: '#f5f5f5', padding: '1rem' }}>
            <FileDownloadIcon />
            <p>Summer 2022 Taxes Paid-Unpaid</p>
          </a> */}
        </div>
      <div style={{ maxWidth: '1440px', backgroundColor: '#f5f5f5', margin: '0 auto', padding: '2rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1rem' }}>
          <InfoIcon sx={{ color: '#49CA97', pr: '.5rem'}} />
          <h3 style={{ fontSize: '24px' }}>Note from the Treasurer</h3>
        </div>
        <p>
          Office hours at the Township hall for collection of taxes will be
          Mondays from 9:00am until Noon, and Thursday from 9:00am to 12:00
          Noon. You may call to schedule an appointment other than regular
          office hours to pay your taxes. Please note the due date on your tax
          bills and allow sufficient time for your payment to reach our
          office. We do not accept post dates as due dates. Interest fees
          begin the day after the posted due date. Taxes become delinquent on
          March 1st of each tax year and tax bills are passed on to the county
          treasures office where additional fees will apply. When paying tax
          bills, please make your checks payable to Coldsprings Township.
          <span>
            {" "}
            Checks made out to Kalkaska County Treasurer will be returned.
          </span>
        </p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '5rem', marginTop: '3rem', marginBottom: '3rem' }}>
        <div style={{ flex: '1', maxWidth: '400px', backgroundColor: '#f5f5f5', padding: '2rem' }}>
          <h2>Sally Murray</h2>
          <p style={{ fontSize: '24px', color: 'darkgrey' }}>Tax Assessor</p>
          {/* <p>I have been involved in assessment administration for over 20 years.   I enjoy the mix of meeting new people, analyzing real estate trends and helping people navigate the mysteries of the property tax structure.  In my spare time, I enjoy motorcycling with my husband, spending time with my family and friends and keeping a keen lookout for the nearest tag or estate sale.</p> */}
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <img style={{ paddingRight: '.5rem'}} src="/images/icons/phone.svg" alt="phone icon" />
            (231)-499-7682
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <img style={{ paddingRight: '.5rem'}} src="/images/icons/email.svg" alt="Email" />
            sallyannmurray8120@gmail.com
          </div>
          <span>
            No Office Hours By Appointment{" "}
            <span>Only!</span>
          </span>
        </div>
        <div style={{ flex: '1', maxWidth: '400px', backgroundColor: '#f5f5f5', padding: '2rem' }}>
          <h2>Brenda Smith</h2>
          <p style={{ fontSize: '24px', color: 'darkgrey' }}>Township Treasurer</p>
          {/* <p>Something nice and inspiring about Sally</p> */}
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <img style={{ paddingRight: '.5rem'}} src="/images/icons/phone.svg" alt="phone icon" />
            (231)-587-8633
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <img style={{ paddingRight: '.5rem'}} src="/images/icons/email.svg" alt="Email" />
            treasurer@coldspringstwp.com
          </div>
          <span>
            Office Hours Monday's and Thursday's 9 a.m. through 12 p.m.
          </span>
        </div>
      </div>
    </motion.div>
  );
}

export default TaxInfo;
