import axios from "axios";
import {
    ADD_PARK_SUBMISSION,
    GET_PARK_SUBMISSIONS,
    REJECT_PARK_SUBMISSION,
    APPROVE_PARK_SUBMISSION,
    DELETE_PARK_SUBMISSION
} from "./types.js";

export const addParkSubmission = (data) => dispatch => {
    axios.post("/api/v1/parks-recreation/create", data)
    .then(res => {
        dispatch({
            type: ADD_PARK_SUBMISSION,
            payload: res.data
        });
    })
    .catch(err => console.log(err));
};

export const getParkSubmissions = () => dispatch => {
    axios.get("/api/v1/parks-recreation")
    .then(res => {
        dispatch({
            type: GET_PARK_SUBMISSIONS,
            payload: res.data
        });
    });
};
export const approveParkSubmission = (data) => dispatch => {
    axios.post("/api/v1/parks-recreation/approve", data)
    .then(res => {
        dispatch({
            type: APPROVE_PARK_SUBMISSION,
            payload: res.data
        });
    })
    .catch(err => console.log(err))
};
export const rejectParkSubmission = (data) => dispatch => {
    axios.post("/api/v1/parks-recreation/reject", data)
    .then(res => {
        dispatch({
            type: REJECT_PARK_SUBMISSION,
            payload: res.data
        });
    });
};
export const deleteParkSubmission = (data) => dispatch => {
    console.log(data);
    dispatch({
        type: DELETE_PARK_SUBMISSION,
        payload: data
    })
}