import React, { useState } from 'react';
import './index.scss';
import { Button, TextField } from '@material-ui/core';
import axios from 'axios';

function AddMember() {
    const [data, setData] = useState({})
    const [btnText, setBtnText] = useState("Add Member")

    const onSubmit = async (e) => {
        e.preventDefault();
        const submittedData = new FormData();
        submittedData.append('file', data.file);
        submittedData.append('fname', data.fname);
        submittedData.append('lname', data.lname);
        submittedData.append('title', data.title);
        submittedData.append('email', data.email);
        submittedData.append('phone', data.phone);
        axios.post('/api/v1/board-members/add', submittedData)
            .then((res) => {
                console.log(res);
                document.getElementById("memberForm").reset();
                setBtnText("Added!")
            })
            .catch(err => console.log(err)
        )
        
    }   

    return (
        <form id="memberForm" className="addmember__container" onSubmit={onSubmit}>
            <p>Profile Picture</p>
            <input required accept="image/*" onChange={(e) => setData({...data, file: e.target.files[0]})} className="addmember__picture__upload" type="file" />
            <div className="addmember__input__container">
                <TextField required onChange={(e) => setData({...data, fname: e.target.value})} className="addmember__input" label="First Name" variant="outlined" />
                <TextField required onChange={(e) => setData({...data, lname: e.target.value})} className="addmember__input" label="Last Name" variant="outlined" />
            </div>
            <div className="addmember__input__container">
                <TextField required onChange={(e) => setData({...data, title: e.target.value})} className="addmember__input" label="Title" variant="outlined" />
                <TextField onChange={(e) => setData({...data, email: e.target.value})} className="addmember__input" label="Email" variant="outlined" />
            </div>
            <TextField onChange={(e) => setData({...data, phone: e.target.value})} className="addmember__input" label="Phone" variant="outlined" />
            <Button type="submit" style={{marginTop: '1rem'}} variant="contained">{btnText}</Button>
        </form>
    )
}
export default AddMember;
