/* eslint-disable import/no-anonymous-default-export */
import {
    SET_ORDINANCES,
    DELETE_ORDINANCE,
    ORDINANCES_LOADED
} from "../actions/types";

const initialState = { 
    ords: [],
    loaded: false 
};


export default function(state = initialState, action) {
    switch (action.type) {
        case SET_ORDINANCES:
            return {
                ...state,
                ords: action.payload
            };
        case DELETE_ORDINANCE:
            return {
                ...state,
                ords: state.ords.filter((ordinance) => ordinance.id !== action.payload)
              };
        case ORDINANCES_LOADED:
            return {
                ...state,
                loaded: action.payload
            }
        default: {
            return state;
        }
    }
}