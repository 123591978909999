import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import MainNav from './mainnav/MainNav';

function Navigation() {
    const [navbar, setNavbar] = useState(false);
    let location = useLocation();

    const urlPath = () => {
        if(location.pathname.includes('/dashboard')) {
            setNavbar(true);
        } else {
            setNavbar(false)
        }
    }
    useEffect(() => {
        urlPath();
    }, [urlPath])

    return (
        <div>
            {(!navbar) ? <MainNav /> : null }
        </div>
    )
}

export default Navigation;
