import Home from './views/home';
import Login from './views/login';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from "./components/privateRoutes/PrivateRoutes";
import Register from './views/register';
import DashBoard from './views/dashboard';
import { Provider } from 'react-redux';
import store from "./store";
import Navigation from './components/navigation';
import Auth0ProviderWithHistory from './auth/auth0-provider'

import './App.css';
import Board from './views/board';
import Minutes from './views/minutes';
import Ordinances from './views/ordinances';
import Parks from './views/parks';
import Contact from './views/contact/index';
import Trash from './views/trash';
import Tax from './views/tax';
import Cemetery from './views/cemetery';
import Footer from './components/footer';
import ContactSub from './views/dashboard/views/contact'
import DashParks from './views/dashboard/views/parks';
import DashSettings from './views/dashboard/views/settings';
import DashUserInfo from './views/dashboard/views/userinfo';
import DashOrdinances from './views/dashboard/views/ordinances';
import DashMinutes from './views/dashboard/views/minutes';
import BoardMembers from './views/dashboard/views/board';
import { AnimatePresence } from 'framer-motion';
import VoterInfo from './views/dashboard/views/voterinfo';
import Voter from './views/voter'
import { UserProvider } from './providers/UserProvider';


//test
function App() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <div style={{ backgroundColor: '#c4c4c4', height: '100px', width: '250px', zIndex: '100', position: 'absolute', top: '300px', boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.75)', borderRadius: '0 6px 6px 0', display: 'flex', alignItems: 'center', padding: '1rem' }}>
              <img src="https://res.cloudinary.com/dam8havko/image/upload/v1717907122/9194679_construction_building_equipment_working_worker_icon_fphrpd.svg" alt="Site Under Construction" style={{ height: '65px'}} />
              <p style={{ textAlign: 'center' }}>Site undergoing maintenance. Will be fully functional 6/12/2024.
              </p>
            </div>
            <Auth0ProviderWithHistory>
              <UserProvider>
                <Navigation loggedIn={false} />
                <AnimatePresence>
                  <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                    <Route path="/government/board-members" component={Board} />
                    <Route path="/government/meetings-minutes" component={Minutes} />
                    <Route path="/government/ordinances" component={Ordinances} />
                    <Route path="/parks-recreation" component={Parks} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/trash" component={Trash} />
                    <Route path="/government/tax" component={Tax} />
                    <Route path="/cemetery" component={Cemetery} />
                    <Route path="/government/voter-info" component={Voter} />
                    <Switch>
                      <PrivateRoute exact path="/dashboard" component={DashBoard} />
                      <PrivateRoute path="/dashboard/contact-submissions" component={ContactSub} />
                      <PrivateRoute path="/dashboard/park-submissions" component={DashParks} />
                      <PrivateRoute path="/dashboard/settings" component={DashSettings} />
                      <PrivateRoute path="/dashboard/user-info" component={DashUserInfo} />
                      <PrivateRoute path="/dashboard/ordinances" component={DashOrdinances} />
                      <PrivateRoute path="/dashboard/meetings-minutes" component={DashMinutes} />
                      <PrivateRoute path="/dashboard/board-members" component={BoardMembers} />
                      <PrivateRoute path="/dashboard/voter-info" component={VoterInfo} />
                    </Switch>
                  </Switch>
                </AnimatePresence>
                <Footer />
              </UserProvider>
            </Auth0ProviderWithHistory>
          </div>
        </Router>
      </Provider>
    )
}
export default App;
