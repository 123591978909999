import React from 'react';
import pdfLogo from '../../images/pdf-icon.png';
import './index.css';

function Pdf({filePath, name}){
    const string = `https://coldspringstownship.com/ordinances/${filePath}`;
    console.log(filePath);
    return(
        <div className="pdf-container">
            <a href={string} download title={name}>
                <img src={pdfLogo} alt="pdf logo"/>
                <p>{name}</p>
            </a>
        </div>
    )
}

export default Pdf;