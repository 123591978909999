/* eslint-disable import/no-anonymous-default-export */
import {
    SET_CONTACTS,
    DELETE_CONTACT
} from "../actions/types";

const initialState = {
    loaded: false,
    contacts: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_CONTACTS:
            return {
                ...state,
                contacts: action.payload
            }
        case DELETE_CONTACT: {
            return {
                ...state,
                contacts: state.contacts.filter((contact) => contact.id !== action.payload.id)
            }}
        default: {
            return state;
        }
    }
}