import React, { useEffect } from "react";
import Divider from "../../components/divider";
import Pdf from "../../components/pdf";
import "./index.css";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { getOrdinances } from "../../actions/ordinanceActions";
import CircularProgress from "@material-ui/core/CircularProgress";

function Ordinances() {
  const ords = useSelector(state => state.ordinances);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrdinances())
  }, [dispatch])

  const pageVariants = {
    initial: {
      opacity: 0,
      y: "-10vh",
      scale: 0.8,
    },
    in: {
      opacity: 1,
      y: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      y: "100vh",
      scale: 1.2,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.8,
  };

  return (
    <motion.div
      className="ordinances-container"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <h1>Ordinances</h1>
      <p>
        All township ordinances are listed here with the specific rules and
        regulations. Please contact us if you have any questions or concerns!
      </p>
      <Divider />

      <div className="ordinances-container__items">
          
          {
            ords.loaded ?
            ords.ords.map((ord, i) => {
              return <Pdf key={i}  name={ord.name} filePath={ord.filePath}  />
            }) : <CircularProgress />
          }
      </div>
    </motion.div>
  );
}

export default Ordinances;
