import React, { Component } from 'react'
import { connect } from "react-redux";
import {
    Menu,
    MenuItem
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import SettingsIcon from '@material-ui/icons/Settings';
import { Link } from 'react-router-dom';
import { logoutUser } from '../../actions/authActions';
import "./index.css";

class LoginDisplay extends Component {

    onLogoutClick = () => {
        this.props.logoutUser();
    }
    render() {
        return (
            <div className="loginDisplay__container">
                <p>Hello, {this.props.auth.user.name}!</p>
                <Menu menuButton={<SettingsIcon className="loginDisplay__icon" />}>
                    <MenuItem><Link to="/dashboard">Dashboard</Link></MenuItem>
                    <MenuItem onClick={this.onLogoutClick}>Logout</MenuItem>
                </Menu>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth
})
export default connect(
    mapStateToProps,
    {logoutUser}
)(LoginDisplay);
