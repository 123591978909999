import { Divider } from '@material-ui/core';
import { Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { useDispatch } from 'react-redux';
import './Member.scss';
import axios from 'axios';
import { Draggable } from 'react-beautiful-dnd';

function Member({fname, lname, title, email, phone, id, index}) {
    const dispatch = useDispatch();

    const deleteMember = () => {
        const data = { _id: id};
        axios.post("/api/v1/board-members/delete", data)
            .then(res => {
                console.log(res)
                dispatch({
                    type: "DELETE_BOARD_MEMBER",
                    payload: id
                })
            }).catch(err =>  console.log(err));
    }

    return (
        <Draggable draggableId={id} index={index}>
            {provided => (
                <Paper ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="dash__member__container" elevation={1}>
                    <div className="dash__member__top__container">
                        <h5>{title}</h5>
                        <DeleteIcon onClick={deleteMember} />
                    </div>
                    <Divider />
                    <div className="dash__user__container">
                        <p>{fname} {lname}</p>
                        <p>{email}</p>
                        <p>{phone}</p>
                    </div>
                </Paper>
            )}
        </Draggable>
    )
}
export default Member;
