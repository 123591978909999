import axios from "axios";
import {
    SET_BOARD_MEMBERS
} from "./types";

export const getBoardMembers = () => dispatch => {
    axios.get("/api/v1/board-members")
        .then(res => {
            dispatch({
                type: SET_BOARD_MEMBERS,
                payload: res.data
            })
            console.log("Grabbed board members successfully!")
        })
        .catch(err => console.log(err));
}
export const reOrderBoardMembers = (data) => dispatch => {
    dispatch({
        type: SET_BOARD_MEMBERS,
        payload: data
    })
    axios.post("/api/v1/board-members/reorder", data)
        .then(res => {
        })
        .catch(err => console.log(err));
}