import React, { useState, useRef, useEffect } from "react";
import QuickLink from "../../components/quickLink";
import Preview from "../../components/previews";
import Carousel from "react-elastic-carousel";
import { useDispatch } from "react-redux";
import "./index.css";
import { motion } from "framer-motion";
import axios from "axios";
import ReactRotatingText from 'react-rotating-text';
import { getMinutes } from "../../actions/minutesActions";
import { Link } from "react-router-dom";

function Home() {
  const dispatch = useDispatch();
  const [btnText, setBtnText] = useState("Submit");
  const [error, setError] = useState();
  const [data, setData] = useState({});

  useEffect(() => {
    dispatch(getMinutes())
  }, [dispatch])

  const formSubmit = (e) => {
    e.preventDefault();
    if(data.name === "" || data.name === null) {
      setError("You must enter your email to continue.")
    }else {
      axios.post("/api/v1/mailing-list/create", data)
    .then(res => {
      if(res.data.created === true){
        setBtnText("Submitted!");
        setData({...data, email: ""});
        setError("");
      }
    })
    .catch(err => {
      console.log(err)
      setError("This email already exists!")
    });
    }
  };

  const carouselRef = useRef(null);
  let resetTimeout;

  const pageVariants = {
    initial: {
      opacity: 0,
      y: "-10vh",
      scale: 0.8,
    },
    in: {
      opacity: 1,
      y: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      y: "100vh",
      scale: 1.2,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.8,
  };
  return (
    <motion.div
      className="homepage"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="homepage__heading">
        <Carousel
          pagination={false}
          ref={carouselRef}
          className="homepage__carousel"
          itemsToShow={1}
          itemsToScroll={1}
          transitionMs={1500}
          easing="ease"
          enableAutoPlay={false}
          autoPlaySpeed={4000}
          onNextEnd={({ index }) => {
            clearTimeout(resetTimeout);
            if (index === 2) {
              resetTimeout = setTimeout(() => {
                carouselRef.current.goTo(0);
              }, 2500); // same time
            }
          }}
        >
          <div className="homepage__hero-1">
            <div className="overlay"></div>
            <img src="./images/hero.png" alt="hero" />

            <div className="homepage__hero-text">
              <h1 className="main__header">Coldsprings Township</h1>
              <ReactRotatingText className="home__rotating__text" items={['Proudly serving our community since 1943...']} />
            </div>
          </div>
        </Carousel>

        <div className="homepage__quick-links">
          <QuickLink
            path="/government/meetings-minutes"
            name="Meetings"
            img="./images/icons/calendar.png"
          />
          <QuickLink
            path="/parks-recreation"
            name="Parks"
            img="./images/icons/mountains.png"
          />
          <QuickLink
            path="/government/ordinances"
            name="Ordinances"
            img="./images/icons/ordinances.png"
          />
          <QuickLink
            path="/trash"
            name="Trash"
            img="./images/icons/trash.png"
          />
          <QuickLink
            path="/government/voter-info"
            name="Voter Info"
            img="./images/icons/tree.png"
          />
        </div>
      </div>
      <div>
        
      </div>
      <div className="homepage__previews">
        <Preview
          title="Park Reservations"
          info="The Sands Park Pavilion is available for a rental fee of $25.  A request to rent can be submitted via this website by completing the form! Click 'Read More' to view!"
          index={1}
          link="/parks-recreation"
        />
        <Preview
          title="Tax Information"
          info="Office hours at the Township hall for collection of taxes will be Mondays from 9:00am until Noon, and Thursday from 9:00am to Noon. You may call to schedule an appointment outside of the regular office hours. Please note the due date on your tax bills and allow sufficient time for your payment to reach our office. We do not accept postdates as due dates. Interest fees begin the day after the posted due date. Taxes become delinquent on March 1st of each tax year and tax bills are passed on to the county treasures office where additional fees will apply. When paying tax bills, please make your checks payable to Coldsprings Township. Checks made out to Kalkaska County Treasurer will be returned."
          index={2}
          link="/government/tax"
        />
        <Preview
          title="Board Meetings"
          info="You can access all Township Board Meeting postings, agendas and minutes (both draft and approved) here.  All meetings are in person at the Township Hall, 6515 County Road 571 NE, Mancelona, MI  49659.  Residents and the public can also participate via Zoom Conference.  Zoom link can be found on each monthly posting and the agenda."
          index={3}
          link="/government/meetings-minutes"
        />
      </div>
      <div style={{ backgroundColor: '#f8f8f8', display: 'flex', justifyContent: 'center', padding: '1rem' }}>
        <Link style={{ textDecoration: 'none', color: '#49ca97' }} to="/government/tax">
          Notice: Click to checkout paid and unpaid taxes for Winter and Summer of 2022
        </Link>
      </div>
      <div className="homepage__mailing-list">
        <h2>Join Our Mailing List!</h2>
        <form className="mailing__list__form" onSubmit={formSubmit}>
          <input required type="email" className="mailing__list__input" value={data.email} onChange={(e) => setData({...data, email: e.target.value})} placeholder="Email" />
          
          <input className="mailing__list__button" type="submit" value={btnText} />
        </form>
        {
            !error ? null : <span className="mailing__list__input__error">{error}</span> 
          }
        <p>
          *All of our email data is kept confidentially and only used for our
          purposes
        </p>
      </div>
    </motion.div>
  );
}
export default Home;
