
export * from "./ordinanceActions";

export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_ORDINANCES = "SET_ORDINANCES";
export const DELETE_ORDINANCE = "DELETE_ORDINANCE";
export const SET_CONTACTS = "SET_CONTACTS";
export const ORDINANCES_LOADED = "ORDINANCES_LOADED";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const ADD_MINUTE = "ADD_MINUTE";
export const GET_MINUTES = "GET_MINUTES";
export const DELETE_MINUTE = "DELETE_MINUTE"
export const ADD_PARK_SUBMISSION = "ADD_PARK_SUBMISSION";
export const SET_PARK_LOADING = "SET_PARK_LOADING";
export const GET_PARK_SUBMISSIONS = "GET_PARK_SUBMISSIONS";
export const REJECT_PARK_SUBMISSION = "REJECT_PARK_SUBMISSION";
export const APPROVE_PARK_SUBMISSION = "APPROVE_PARK_SUBMISSION"; 
export const DELETE_PARK_SUBMISSION = "DELETE_PARK_SUBMISSION";
export const SET_BOARD_MEMBERS = "SET_BOARD_MEMBERS";
export const DELETE_BOARD_MEMBER = "DELETE_BOARD_MEMBER";