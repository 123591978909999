import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <button
      style={{ fontSize: '1rem', marginLeft: '1rem', cursor: 'pointer', border: 'none' }}
      onClick={() => loginWithRedirect()}
    >
      Log In
    </button>
  );
};

export default Login;