import axios from "axios";
import {
    ADD_MINUTE,
    GET_MINUTES,
    DELETE_MINUTE
} from "./types";

export const addMinute = (data) => dispatch => {
    axios.post("/api/v1/minutes/create", data)
    .then(res => {
        dispatch({
            type: ADD_MINUTE,
            payload: res.data
        });
        alert('File successfully uploaded.')
    })
    .catch(err => console.log(err));
}
export const getMinutes = () => dispatch => {
    axios.get("/api/v1/minutes")
    .then(res => {
        console.log('api fetch worked...')
        dispatch({
            type: GET_MINUTES,
            payload: res.data.reverse()
        })
    })
    .catch(err => console.log(err))
}

export const deleteMinute = (data) => dispatch => {
    axios.post("/api/v1/minutes/delete", data)
    .then(res => {
        console.log(res)
        dispatch({
            type: DELETE_MINUTE,
            payload: res.data
        });
    })
    .catch(err => console.log(err));
}