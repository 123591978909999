import React from 'react';
import './index.css';

function Border(props){
    return(
        <div className="border">
            <span className="border-line"></span>
            <h2>{props.title}</h2>
            <span className="border-line"></span>
        </div>
    )
}

export default Border;