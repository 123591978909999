import React, { useState } from 'react';
import DashboardNav from "../../../../components/navigation/dashboardnav";
import SideNav from "../../../../components/navigation/dashsidenav";
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import { TabPanel } from '@material-ui/lab';
import './index.scss';
import AddMember from './components/AddMember';
import ViewMembers from './components/ViewMembers';

function Board() {
    const [tabValue, setTabValue] = useState('1');
    //test

    return (
            <div className="dashboard__parent__container">
                <SideNav />
                <div className="dashboard__child__container">
                    <DashboardNav />
                    <Paper className="dashboard__ordinance__header" elevation={4}>
                        <Typography style={{color: '#49CA97'}} variant="h5">Board Members</Typography>
                        <Typography variant="p">Manage all of your Township Board Members here! These are reflected publicly on the site.</Typography>
                    </Paper>
                    <Paper elevation={2} className="dashboard__board__container">
                        <Box sx={{ maxWidth: '100%', typography: 'body1'}}>
                            <TabContext value={tabValue}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divder' }} >
                                    <TabList onChange={(e,newValue) => setTabValue(newValue)} aria-label="Board Member Tabs">
                                        <Tab label="Board" value="1" />
                                        <Tab label="Add Member"  value="2" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <ViewMembers />
                                </TabPanel>
                                <TabPanel value="2">
                                    <AddMember />
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Paper>
                </div> 
            </div>
        )
}
export default Board;