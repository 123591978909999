/* eslint-disable import/no-anonymous-default-export */
import {
    SET_BOARD_MEMBERS,
    DELETE_BOARD_MEMBER
} from "../actions/types";

const initialState = {
    members: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_BOARD_MEMBERS:
            return {
                ...state,
                members: action.payload
            }
        case DELETE_BOARD_MEMBER:
            return {
                ...state,
                members: state.members.filter((member) => member._id !== action.payload)
            }
        default: {
            return state;
        }
    }
}