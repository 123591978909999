import React from "react";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PersonIcon from '@material-ui/icons/Person';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import CallIcon from '@material-ui/icons/Call';
import DeleteIcon from '@material-ui/icons/Delete';
import PeopleIcon from '@material-ui/icons/People';
import EventIcon from '@material-ui/icons/Event';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useDispatch } from "react-redux";
import './index.scss';
import { Button, Divider } from "@material-ui/core";
import { approveParkSubmission, rejectParkSubmission } from "../../../actions/parkActions";
import axios from "axios";

function ParkSubmissionModal({open,setOpen,id,fname,lname,email,phone,guests,date,reason,approved,setSnackOpen}) {
    //test
    const dispatch = useDispatch();
    const deleteParkSubmission = () => {
        const data = {"_id": id};
        axios.post("/api/v1/parks-recreation/delete", data)
        .then((res) => {
            dispatch({
                type: "DELETE_PARK_SUBMISSION",
                payload: data
            });
            setOpen(false);
            setSnackOpen(true);
        })
        .catch(err => console.log(err));
    }
    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}>
            <Fade in={open}>
                <div className="parks__modal__form">
                    <div className="parks__modal__form__header">
                        <h3>Park Submission</h3>
                        <p onClick={deleteParkSubmission} className="parks__modal__delete"><DeleteIcon className="park__modal__delete" /></p>
                    </div>
                    <Divider />
                    <div className="parks__modal__row">
                        <TextField
                            className="parks__modal__one"
                            label="Name"
                            value={fname + " " + lname}
                            disabled
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                            ),
                        }}/>
                        <TextField
                            className="parks__modal__one"
                            label="Email"
                            value={email}
                            disabled
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AlternateEmailIcon />
                                </InputAdornment>
                            ),
                        }}/>
                    </div>
                    
                    <div className="parks__modal__row">
                        <TextField
                            className="parks__modal__two"
                            label="Guests"
                            value={guests}
                            disabled
                            type="number"
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PeopleIcon />
                                </InputAdornment>
                            ),
                        }}/>
                        <TextField
                            className="parks__modal__two"
                            label="Phone"
                            value={phone}
                            disabled
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CallIcon />
                                </InputAdornment>
                            ),
                        }}/>
                        <TextField
                            className="parks__modal__two"
                            label="Reserve Date"
                            value={date}
                            disabled
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EventIcon />
                                </InputAdornment>
                            ),
                        }}/>
                    </div>
                    <TextField
                        className="parks__modal__multiline"
                        disabled
                        label="Reason to rent..."
                        multiline
                        rows={8}
                        maxRows={8}
                        value={reason}
                    />
                    <div className="parks__modal__btn__group">
                        <Button onClick={() => {
                            dispatch(rejectParkSubmission({"_id": id, "email": email}));
                            setOpen(false);
                        }} variant="contained" disabled={approved === "Rejected" ? true : false} style={{backgroundColor: '#ff4f4d'}}>Reject</Button>
                        <Button onClick={() => {
                            dispatch(approveParkSubmission({"_id": id, "email": email}));
                            setOpen(false);
                        }} variant="contained" disabled={approved === "Approved" ? true : false} style={{backgroundColor: '#5be37f'}}>Approve</Button>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

export default ParkSubmissionModal;