import React, { useEffect, useState } from "react";
import { Paper, Typography, Divider, Button } from "@material-ui/core";
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import GavelIcon from '@material-ui/icons/Gavel';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { deleteContactSubmission } from "../../actions/contactActions";
import { useDispatch } from 'react-redux';
import Moment from 'react-moment';
import "./index.scss";

function ContactSubmissionItem({email, name, phone, entity, message, read, date, id}) {
    const [time, setTime] = useState()
    const dispatch = useDispatch();

    useEffect(() => {
        if (date) {
            setTime(new Date(date))
        }
    }, [date])
    
    return (
        <Paper className="contact__submission__item__container" elevation={1}>
            <div className="contact__submission__item__header__container">
                <div className="contact__submission__item__header">
                    <EmailIcon className="contact__submission__item__header__icon" />
                    <Typography variant="h6">{email}</Typography>
                </div>
                {
                    !read ? <NotificationsIcon  className="notty__icon" /> : null
                }
            </div>
            <Divider />
            <div className="contact__submission__item__content">
                <div className="contact__submission__item__section">
                    <PersonIcon className="submission__item__icon"/>
                    <p>{name}</p>
                </div>
                <div className="custom__divider"/>
                <div className="contact__submission__item__section">
                    <PhoneIcon className="submission__item__icon"/>
                    <p>{phone}</p>
                </div>
                <div className="custom__divider"/>
                <div className="contact__submission__item__section">
                    <GavelIcon className="submission__item__icon"/>
                    <p>{entity}</p>
                </div>
            </div>
            <p className="contact__submission__item__message">{message}</p>
            <Divider />
            <div style={{ display: 'flex', alignItems: 'center', width: '100%'}}>
                <Button>Mark As Read</Button>
                <Button onClick={() => {dispatch(deleteContactSubmission({id: id}))}}>Delete</Button>
                {/* <Typography sx={{ marginLeft: 'auto' }} variant="body1">Received on: {}`}</Typography> */}
                <Moment style={{ marginLeft: 'auto' }} format="MM/DD/YYYY" date={time} />
            </div>
            
        </Paper>
    )
}
export default ContactSubmissionItem;
