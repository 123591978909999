import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';

import IconButton from '@material-ui/core/IconButton';
import './index.scss';

export const MinuteCont = ({title, id, trashClick}) => {
    return (
        <Paper className="minute__component__container" elevation={2}>
            <p>{title}</p>
            <IconButton>
                <DeleteIcon onClick={() => trashClick(id)} />
            </IconButton>
        </Paper>
    )
}
