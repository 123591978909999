import React, { useEffect} from 'react';
import DashboardNav from '../../components/navigation/dashboardnav';
import { useSelector, useDispatch } from 'react-redux';
import SideNav from '../../components/navigation/dashsidenav';
import Paper from '@material-ui/core/Paper';
import ContactsIcon from '@material-ui/icons/Contacts';
import './index.css';
import { Grid, Typography } from "@material-ui/core";
import NatureIcon from '@material-ui/icons/Nature';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { getBoardMembers } from '../../actions/boardActions';

function Dashboard() {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBoardMembers())
    }, [dispatch])

    return (
        <div className="dashboard__parent__container">
            <SideNav />
            <div className="dashboard__child__container">
                <DashboardNav />
                <div className="dashboard__content__container">
                    <Paper elevation={3} className="dashboard__welcome__container">
                        <Typography style={{color: '#49CA97'}} variant="h5">Welcome, {auth.user.name}!</Typography>
                        <p>This is where you manage all content on your site. If you would like any changes please submit a support ticket on <a href="https://digitalbyte.io">Digitalbyte</a>.</p>
                    </Paper>
                    <Grid className="dashboard__content__notification__panel" container spacing={3}>
                        <Grid item xs={4} className="dashboard">
                            <Paper elevation={4} className="dashboard__content__grid__item">
                                <ContactsIcon id="icon__contact" className="dashboard__content__notification__icon" />
                                <Typography variant="h3" component="h6"  style={{color: 'darkgray'}}>10</Typography>
                                <div style={{backgroundColor: '#03dbfc'}} className="dashboard__content__grid__item__bottom">
                                    <Typography style={{padding: '.5rem'}} variant="h6">Contact Submissions</Typography>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper elevation={4} className="dashboard__content__grid__item">
                                <NatureIcon id="icon__tree" className="dashboard__content__notification__icon" /><Typography variant="h3" component="h6" style={{color: 'darkgray'}}>3</Typography>
                                <div className="dashboard__content__grid__item__bottom" style={{backgroundColor: '#56a865'}}>
                                    <Typography style={{padding: '.5rem'}} variant="h6">Park Submissions</Typography>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper elevation={4} className="dashboard__content__grid__item">
                                <EqualizerIcon id="icon__stats" className="dashboard__content__notification__icon" /><Typography variant="h3" component="h6" style={{color: 'darkgray'}} >1,301</Typography>
                                <div style={{backgroundColor: '#ff4d4d'}} className="dashboard__content__grid__item__bottom">
                                    <Typography style={{padding: '.5rem'}} variant="h6">Site Views</Typography>
                                </div>
                            </Paper>
                        </Grid>
                        
                    </Grid>
                </div>
            </div> 
        </div>
    )
}
export default Dashboard;