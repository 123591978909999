import { Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import VisibilityIcon from '@material-ui/icons/Visibility';
import DashboardNav from "../../../../components/navigation/dashboardnav";
import SideNav from "../../../../components/navigation/dashsidenav";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Snackbar from '@material-ui/core/Snackbar';
import { useSelector, useDispatch } from "react-redux";
import './index.scss';
import MuiAlert from '@mui/material/Alert';
import { getParkSubmissions } from "../../../../actions/parkActions";
import ParkSubmissionModal from "../../../../components/modals/parkSubmissionModal";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
  

function Parks(){
    const parkSubmissions = useSelector(state => state.parks.parkSubmissions);
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [snackOpen, setSnackOpen] = useState(false);

    


    const viewItem = (id,fname,lname,email,phone,guests,date,reason,approved) => {
        setOpen(true);
        setFormData({
            id,
            fname,
            lname,
            email,
            phone,
            guests,
            date,
            reason,
            approved
        })
        console.log(formData);
    }

    useEffect(() => {
        dispatch(getParkSubmissions())
    }, [dispatch])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, parkSubmissions.length - page * rowsPerPage);

    return (
        <div className="dashboard__parent__container">
            <SideNav />
            <div className="dashboard__child__container">
                <DashboardNav />
                <Paper className="parks__dashboard__header" elevation={4}>
                    <Typography style={{color: '#49CA97'}} variant="h5">Park Pavillion Rental</Typography>
                    <Typography variant="p">Accept and Deny park rental applications here. Remember upon "approved" an automated email will be sent telling them so.</Typography>
                </Paper>
                
                <TableContainer className="minutes__table park__dashboard__container" component={Paper}>
                    <Table className="park__table" aria-label="simple table">
                    <TableHead classname="minutes__table__header">
                        <TableRow className="minutes__table__header__row">
                        <TableCell className="minutes__table__header__row__text">Name</TableCell>
                        <TableCell className="minutes__table__header__row__text">Phone</TableCell>
                        <TableCell className="minutes__table__header__row__text"># of Guests</TableCell>
                        <TableCell className="minutes__table__header__row__text">Reserve Date</TableCell>
                        <TableCell className="minutes__table__header__row__text">Approved</TableCell>
                        <TableCell className="minutes__table__header__row__text">View</TableCell>
                        {/* <TableCell className="minutes__table__header__row__text" align="right">Year</TableCell>
                        <TableCell className="minutes__table__header__row__text" align="right">Month</TableCell>
                        <TableCell className="minutes__table__header__row__text" align="right">Download</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { Array.isArray(parkSubmissions) ? 
                        parkSubmissions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item, index) => (
                            <TableRow key={item._id}>
                                <TableCell component="th" scope="row">
                                    {item.fname} {item.lname}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.phone}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.guests}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.reserveDate}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.approved}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <VisibilityIcon onClick={() => viewItem(item._id,item.fname,item.lname,item.email,item.phone,item.guests,item.reserveDate,item.reason,item.approved)} />
                                </TableCell>
                            </TableRow>
                        )) : <p>No rows defined.</p>}
                        {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                        )
                    }
                    </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={parkSubmissions.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableContainer>
                <Snackbar
                    anchorOrigin={{ 
                        vertical : 'bottom', 
                        horizontal: 'right' 
                    }}
                    autoHideDuration={6000}
                    open={snackOpen}
                    onClose={()=> setSnackOpen(false)}
                    message="I love snacks"
                >
                    <Alert onClose={()=> setSnackOpen(false)} severity="success">
                        Park submission successfully deleted!
                    </Alert>
                </Snackbar>
                <ParkSubmissionModal 
                    open={open} 
                    setOpen={setOpen} 
                    id={formData.id}
                    fname={formData.fname}
                    lname={formData.lname}
                    email={formData.email}
                    phone={formData.phone}
                    guests={formData.guests}
                    date={formData.date}
                    reason={formData.reason}
                    approved={formData.approved}
                    setSnackOpen={setSnackOpen}
                />
            </div> 
        </div>
    )
}
export default Parks;