import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';

import IconButton from '@material-ui/core/IconButton';
import './index.scss';

export const OrdinanceCont = ({name, id, setOpen, setOrdId}) => {
    return (
        <Paper className="ord__component__container" elevation={2}>
            <p>{name}</p>
            <IconButton>
                <DeleteIcon onClick={() => {
                    setOrdId(id)
                    setOpen(true)
                    }} />
            </IconButton>
        </Paper>
    )
}
