import React, {useState, useEffect} from 'react';
import SideNav from '../../../../components/navigation/dashsidenav';
import DashboardNav from '../../../../components/navigation/dashboardnav';
import { uploadOrdinance } from '../../../../actions/ordinanceActions';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOrdinance } from "../../../../actions/ordinanceActions";
import axios from 'axios';
import './index.scss';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { OrdinanceCont } from '../../../../components/ordinance';
import { getOrdinances } from '../../../../actions/ordinanceActions';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';

function Ordinances() {
    const ordinances = useSelector(state => state.ordinances);
    const [open, setOpen] = useState(false);
    const [ordId, setOrdId] = useState();
    const [agree, setAgree] = useState(false);
    const [oName, setoName] = useState();
    const [file, setFile] = useState();
    const [loaded, setLoaded] = useState(false);
    const [uploadedFile, setUploadedFile] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOrdinances());
        if(agree === true) {
            deleteOrd();
        }
    }, [dispatch, agree])

    const onSubmit = async (e) => {
        e.preventDefault();
        const fileData = new FormData();
        fileData.append('file', file);
        fileData.append('oName', oName);
        dispatch(uploadOrdinance(fileData))
        return dispatch(getOrdinances());
    }
    const deleteOrd = () => {
        if(agree === true) {
            setOpen(false)
            dispatch(deleteOrdinance({id: ordId}));
            dispatch(getOrdinances());
            setAgree(false)
        }
        dispatch(getOrdinances());
    }
    return (
        <div className="dashboard__parent__container">
            <SideNav />
            <div className="dashboard__child__container">
                <DashboardNav />
                <Paper className="dashboard__ordinance__header" elevation={4}>
                    <Typography style={{color: '#49CA97'}} variant="h5">Ordinance Management</Typography>
                    <Typography variant="p">Manage all township ordinances here. Remember these changes are reflected publicly for everyone to see.</Typography>
                </Paper>
                <Paper elevation={4} className="dashboard__ordinance__container">
                    <div className="dashboard__ordinanace__section">
                        <div className="dashboard__ordinance__section__item">
                            <Typography variant="h5">Add Ordinance</Typography>
                            <form className="picture__form" onSubmit={(e) => onSubmit(e)}>
                                {/* <input value={oName} onChange={(e) => setoName(e.target.value)} /> */}
                                <TextField required id="FileName" value={oName} onChange={(e) => setoName(e.target.value)} label="Ordinance Title" variant="outlined" />
                                <input required accept="application/pdf" className="file__upload__btn" onChange={(e)=> setFile(e.target.files[0])} type="file" />
                                <Button type="submit" variant="contained">Add Ordinance</Button>
                            </form>
                        </div>
                        <div className="ordinance__seperator" />
                        <div className="dashboard__ordinance__section__item">
                            <Typography variant="h5">Manage Ordinances</Typography>
                            <div className="ordinance__dashboard__help__display">
                                <span>Ordinance</span>
                                <span>Delete</span>
                            </div>
                            <div className="ordinance__dashboard__delete__divider" />
                            {
                                ordinances.ords ? (
                                ordinances.ords.map((item, i) => {
                                    return <OrdinanceCont setOrdId={setOrdId} setOpen={setOpen} agree={agree} key={i} id={item._id} name={item.name} />
                                })) : []
                                
                            }
                        </div>
                    </div>
                </Paper>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                className="dashboard__ordinances__modal"
                onClose={() => setOpen(!open)}
                closeAfterTransition>
                <Fade in={open}>
                    <div className="dashboard__ordinances__modal__content">
                        <h4 id="transition-modal-title">Confirmation</h4>
                        <p id="transition-modal-description">Are you sure you want to delete this ordinance?</p>
                        <div className="dashboard__ordinances__modal__btn__group">
                            <Button onClick={() => setOpen(false)} variant="contained">Cancel</Button>
                            <Button onClick={()=> {
                                setAgree(true);
                                setOpen(false);
                            }} variant="contained" style={{backgroundColor: '#ff4f4d'}}>Confirm Delete</Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}

export default Ordinances;
