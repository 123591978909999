import React, { useState, useCallback, useEffect } from "react";
import Heading from "../../components/heading";
import "./index.css";
import { motion } from "framer-motion";
import { createReactEditorJS } from "react-editor-js";
import { EDITOR_JS_TOOLS } from "../../views/dashboard/views/voterinfo/Constants";
import axios from 'axios';

const ReactEditorJS = createReactEditorJS();

export default function Voter() {
    const editorCore = React.useRef(null);
    const [ page, setPage ] = useState();
    const [ isLoaded, setLoaded ] = useState();
    const handleInitialize = useCallback((instance) => {
        editorCore.current = instance
    }, [])

    useEffect(() => {
        axios.get("/api/v1/pages", { params: { name: 'voterinfo' } })
        .then(res => {
            setPage(res.data)
            setLoaded(true)
        })
    }, [])
    
  const pageVariants = {
    initial: {
      opacity: 0,
      y: "-10vh",
      scale: 0.8,
    },
    in: {
      opacity: 1,
      y: 0,
      scale: 1,
    },
        out: {
            opacity: 0,
            y: "100vh",
            scale: 1.2,
        },
    };

    const pageTransition = {
        type: "tween",
        ease: "anticipate",
        duration: 0.8,
    };

    return (
        <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}>
            <Heading
                img="https://i.ibb.co/WpSHjVt/element5-digital-ls8-Kc0-P9h-AA-unsplash.jpg"
                title="Voter Information"
            />
            {
                isLoaded ? <ReactEditorJS
                readOnly={true}
                onInitialize={handleInitialize}
                tools={EDITOR_JS_TOOLS}
                data={page.data[0]}
            /> :
            <p>Loading</p>
            }
            
        </motion.div>
    );
}
