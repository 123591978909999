import axios from "axios";
import {
    SET_ORDINANCES,
    DELETE_ORDINANCE,
    ORDINANCES_LOADED
} from "./types";

export const uploadOrdinance = (data) => dispatch => {
    axios.post("/api/v1/ordinances/create", data)
    .then(res => {
        dispatch(getOrdinances());
    })
    .catch(err => console.log(err))
}

export const getOrdinances = () => dispatch => {
    axios.get("/api/v1/ordinances")
    .then(res => {
        const data = res.data;
        dispatch(setOrdinances(data));
        dispatch(ordinancesLoaded(true));
    })
    .catch(err => console.log(err))
}
export const deleteOrdinance = (data) => dispatch => {
    console.log("deleting ordinance")
    axios.post("/api/v1/ordinances/delete", data)
    .then(res =>{
        dispatch(deleteOrdinances(data));
        dispatch(getOrdinances());
    })
    .catch(err => console.log(err));
}
//set the ordinance data 
export const setOrdinances = data => {
    return {
        type: SET_ORDINANCES,
        payload: data
    };
};
export const deleteOrdinances = data => {
    return {
        type: DELETE_ORDINANCE,
        payload: data
    };
};
export const ordinancesLoaded = (data) => {
    return {
        type: ORDINANCES_LOADED,
        payload: data
    };
};